import React from 'react'
import Header from '../components/common/Header'
import AppCTA from '../components/cta/AppCTA'
import GeneralCTA from '../components/cta/GeneralCTA.js'
import NewsletterCTA from '../components/cta/NewsletterCTA.js'
import Layout from '../components/layout'
import ResourceGrid from '../components/resources/ResourceGrid.js'
import Seo from '../components/seo'

class RootIndex extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <Seo
          title="Resources and downloads | Urban Fox EV Charging"
          description="Free to access resources on the latest EV charging topics and trends as well as Urban Fox news and updates."
        />
        <Header filter="Downloads" />
        <ResourceGrid />
        <NewsletterCTA />
        <AppCTA />
        <GeneralCTA />
      </Layout>
    )
  }
}

export default RootIndex
