import { graphql, useStaticQuery } from 'gatsby'
import get from 'lodash/get'
import React from 'react'
import Icon from '../common/Icon'
import Resource from './Resource'

const ResourceGrid = (props) => {
  const data = useStaticQuery(graphql`
    query downloadQuery {
      allContentfulDownloads(sort: { fields: [order], order: DESC }) {
        nodes {
          title
          slug
          description {
            raw
          }
          order
          image {
            gatsbyImage(layout: FULL_WIDTH, height: 1200)
          }
        }
      }
    }
  `)

  const resources = get(data, 'allContentfulDownloads.nodes', null)

  if (!resources) {
    return (
      <div className="w-full max-w-7xl mx-auto px-6">
        <h3 className="text-3xl font-bold text-center text-gray-900 py-16">
          No Resources found.
        </h3>
        <Icon name="FileIcon" className="w-full h-24 mb-24 text-uforange-600" />
      </div>
    )
  }

  return (
    <div className="my-12 grid gap-5 grid-cols-1 lg:max-w-none lg:grid-cols-2 max-w-7xl mx-auto">
      {resources.map((resource) => (
        <Resource resource={resource} />
      ))}
    </div>
  )
}

export default ResourceGrid
